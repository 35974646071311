.text-lexical-link-editor {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    max-width: 400px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
    will-change: transform;
}

.text-lexical-link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
}

.text-lexical-link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
}

.text-lexical-link-editor .button i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
}

.text-lexical-link-editor {
    background-color: #eee;
}

.text-lexical-link-editor .text-lexical-link-input {
    display: block;
    width: calc(100% - 75px);
    box-sizing: border-box;
    margin: 12px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
}

.text-lexical-link-editor .text-lexical-link-view {
    display: block;
    width: calc(100% - 24px);
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
}

.text-lexical-link-editor .text-lexical-link-view a {
    display: block;
    word-break: break-word;
    width: calc(100% - 33px);
}

.text-lexical-link-editor div.text-lexical-link-edit {
    background-image: url(../../images/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.text-lexical-link-editor div.text-lexical-link-trash {
    background-image: url(../../images/trash.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.text-lexical-link-editor div.text-lexical-link-cancel {
    background-image: url(../../images/close.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    margin-right: 28px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.text-lexical-link-editor div.text-lexical-link-confirm {
    background-image: url(../../images/success-alt.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    margin-right: 2px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.text-lexical-link-editor .text-lexical-link-input a {
    color: rgb(33, 111, 219);
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
}

.text-lexical-link-editor .text-lexical-link-input a:hover {
    text-decoration: underline;
}

.text-lexical-link-editor .text-lexical-font-size-wrapper,
.text-lexical-link-editor .text-lexical-font-family-wrapper {
    display: flex;
    margin: 0 4px;
}

.text-lexical-link-editor select {
    padding: 6px;
    border: none;
    background-color: rgba(0, 0, 0, 0.075);
    border-radius: 4px;
}
