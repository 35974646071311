.text-lexical-table-cell-action-button-container {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
}

.text-lexical-table-cell-action-button {
    background-color: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    position: relative;
    border-radius: 15px;
    color: #222;
    display: inline-block;
    cursor: pointer;
}
i.text-lexical-chevron-down {
    background-color: transparent;
    background-size: contain;
    display: inline-block;
    height: 8px;
    width: 8px;
    background-image: url(images/chevron-down.svg);
}
button.text-lexical-item i {
    opacity: 0.6;
}

button.text-lexical-item.dropdown-item-active {
    background-color: rgba(223, 232, 250, 0.3);
}

button.text-lexical-item.dropdown-item-active i {
    opacity: 1;
}

.text-lexical-dropdown {
    z-index: 99999;
    display: block;
    position: fixed;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-height: 40px;
    background-color: #fff;
}

.text-lexical-dropdown .text-lexical-item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    max-width: 250px;
    min-width: 100px;
}

.text-lexical-dropdown .text-lexical-item.fontsize-item,
.text-lexical-dropdown .text-lexical-item.fontsize-item .text {
    min-width: unset;
}

.text-lexical-dropdown .text-lexical-item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
}

.text-lexical-dropdown .text-lexical-item:first-child {
    margin-top: 8px;
}

.text-lexical-dropdown .text-lexical-item:last-child {
    margin-bottom: 8px;
}

.text-lexical-dropdown .text-lexical-item:hover {
    background-color: #eee;
}

.text-lexical-dropdown .text-lexical-item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
}

.text-lexical-dropdown .text-lexical-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.text-lexical-dropdown .divider {
    width: auto;
    background-color: #eee;
    margin: 4px 8px;
    height: 1px;
}
