@font-face {
  font-family: 'fontello-reform';
  src: url('../font/fontello-reform.eot?78736508');
  src: url('../font/fontello-reform.eot?78736508#iefix') format('embedded-opentype'),
       url('../font/fontello-reform.woff2?78736508') format('woff2'),
       url('../font/fontello-reform.woff?78736508') format('woff'),
       url('../font/fontello-reform.ttf?78736508') format('truetype'),
       url('../font/fontello-reform.svg?78736508#fontello-reform') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello-reform';
    src: url('../font/fontello-reform.svg?78736508#fontello-reform') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello-reform";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-certificate:before { content: '\f0a3'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */
.icon-error:before { content: '\f2ce'; } /* '' */
.icon-info:before { content: '\f2cf'; } /* '' */
.icon-attach:before { content: '\f2d0'; } /* '' */
.icon-cancel:before { content: '\f2d1'; } /* '' */
.icon-calendar:before { content: '\f2d2'; } /* '' */
.icon-tick:before { content: '\f2d3'; } /* '' */
.icon-cross:before { content: '\f2d4'; } /* '' */
.icon-monitor:before { content: '\f2d5'; } /* '' */
.icon-right-hand:before { content: '\f2d6'; } /* '' */
.icon-link-ext:before { content: '\f2d7'; } /* '' */
.icon-floppy:before { content: '\f2d8'; } /* '' */
.icon-download:before { content: '\f2d9'; } /* '' */
.icon-upload:before { content: '\f2da'; } /* '' */
.icon-edit:before { content: '\f2db'; } /* '' */
.icon-print:before { content: '\f2dc'; } /* '' */
.icon-paper-plane:before { content: '\f2dd'; } /* '' */
.icon-sign:before { content: '\f2de'; } /* '' */
.icon-down-dir:before { content: '\f2df'; } /* '' */
.icon-sticky-note:before { content: '\f2e0'; } /* '' */
.icon-lightbulb:before { content: '\f2e1'; } /* '' */
.icon-plus-circled:before { content: '\f2e2'; } /* '' */
.icon-minus-circled:before { content: '\f2e3'; } /* '' */
.icon-login:before { content: '\f2e4'; } /* '' */
.icon-folder-open-empty:before { content: '\f2e5'; } /* '' */
.icon-sticky-note-o:before { content: '\f2e6'; } /* '' */